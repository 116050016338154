<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title mb-4">Liste des contraventions</h4>
                    <div class="row justify-content-between">
                        <div class="col-md-5">
                            <div class="form-group mt-3 mb-0">
                                <div class="search-box me-2">
                                    <div class="position-relative">
                                        <input type="text" class="form-control bg-soft bg-info border-0"
                                            placeholder="Rechercher..." />
                                        <!-- @input="searchFilter($event)"  -->
                                        <i class="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row col-md-6">
                            <div class="form-group col-md-4 mt-3 mb-0">
                                <datepicker v-model="picked" :first-day-of-week="1" lang="en" confirm class="form-control"
                                    placeholder="Date de début"></datepicker>
                            </div>

                            <div class="form-group col-md-4 mt-3 mb-0">
                                <datepicker class="form-control" placeholder="Date de fin" v-model="daterange" range
                                    append-to-body lang="en" confirm></datepicker>
                            </div>

                            <div class="mt-3 col-md-4 mb-0">
                                <button class="btn btn-info">
                                    <i class="bx bx-slider align-middle me-1"></i> Lancer le filtre
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="table-responsive mt-3"  v-if="!loading">
                        <table class="table table-nowrap table-centered mb-0 align-middle">
                            <thead class="table-light">
                                <tr>
                                    <th>Numéro de plaque</th>
                                    <th>Type engin</th>
                                    <th scope="col">Contravention</th>
                                    <th scope="col">Montant</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Statut</th>
                                     <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="contra in contraData" :key=contra.id>
                                    <td>{{ contra.immatriculation }}</td>
                                    <td>{{ contra.Typevehicule }}</td>
                                    <td>{{ contra.TypeContravention?.nomType }}</td>
                                    <td>{{ contra.montant }}</td>
                                    <td>{{ contra.date_is_receive }}</td>
                                    <td>
                                      <span v-if="`${contra.StatutContravention}` == `non-paye`" class="badge font-size-11 m-1 bg-danger">
                                             Non paye
                                        </span>
                                      <span v-if="`${contra.StatutContravention}` == `paye`" class="badge font-size-11 m-1 bg-success">
                                            Paye
                                        </span>

                                    </td>
                                     <td>
                                        <button @click="detailsContravention(contra)" class="btn btn-warning btn-sm me-2 w-xs">Details</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  <div v-else class="loading-container">
                    <div class="text-center">
                      <i class="bx bx-loader bx-spin" style="font-size: 5em;"></i>
                      <h1>Chargement</h1>
                    </div>
                  </div>
                </div>
            </div>
        </div>
        <!-- end row -->
    </Layout>
</template>
<style>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh
}
</style>
<script>

import Layout from "../../layouts/main";
import Datepicker from "vue3-datepicker";
import PageHeader from "@/components/page-header";
// import { contraData } from "./contraData";

import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { reqMethods } from '../../../state/helpers';


/**
 * Lending component
 */
export default {
    setup() {
        return { v$: useVuelidate() };
    },
    components: {
        Layout, PageHeader,
        Datepicker
    },
    data() {
        return {
            picked: "",
            daterange: "",
          loading: true,
            contraData: {},
            fetchError: null,
            title: "Liste de contravention",
            items: [
                {
                    text: "Dashboard",
                    href: "/"
                },
                {
                    text: "Contraventions",
                    active: true
                }
            ],
            showModal: false,
            submitted: false,
            users: {
                name: "",
                montant: "",
            },
        };
    },
    validations: {
        users: {
            name: {
                required: helpers.withMessage("Name is required", required),
            },
            montant: {
                required: helpers.withMessage("Amount is required", required),
            },
        },
    },
    methods: {
        ...reqMethods,
      detailsContravention(contra){
        this.$router.push({ path: `/details-contavention/${contra.id}` });
      },
      getAllContravention() {
    const that = this;
    this.loading = true;
    this.getRequest('/contravention/contravention/').
        then(
            function (response) {
                that.contraData = response.data.sort((a, b) => new Date(b.date_is_receive) - new Date(a.date_is_receive));
            },
            function (error) {
                that.fetchError = error;
            }
        ).finally(() => {
            that.loading = false;
        });
},

        // searchFilter(e) {
        //     const searchStr = e.target.value;
        //     this.contraData = contraData.filter((contra) => {
        //         return (
        //             contra.author.toLowerCase().includes(searchStr.toLowerCase()) ||
        //             contra.contras.toLowerCase().includes(searchStr.toLowerCase()) ||
        //             contra.montant.toLowerCase().includes(searchStr.toLowerCase())
        //         );
        //     });
        // },
        handleSubmit() {
            this.submitted = true;

            // stop here if form is invalid
            this.v$.$touch();
            if (this.v$.$invalid) {
                return;
            } else {
                this.showModal = false;
                this.users = {};
            }
            this.submitted = false;
        },
    },
    mounted() {
        this.getAllContravention();
    },
};
</script>
